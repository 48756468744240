import React, { useState, useEffect, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import api from '../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import Row from '../../controller/PageRow';
import AuthContext from '../../authentification/context/AunthLogin';
import Header from '../../components/nav/Header';

const UpdateCustomer = () => {
    var data = '';
    const navigate = useNavigate()
    const { setAuth }: any = useContext(AuthContext);
    const [listCountri, setListCountri] = useState<Row[]>([]);
    const [listCategory, setListCategory] = useState<Row[]>([]);
    const [listinvset, setListInvset] = useState([]);
    const [listEdit, setListEdit] = useState([]);
    // console.log(listEdit)
    const { idcuctomer } = useParams();

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    // const userRef = useRef();
    // const errRef = useRef();

    const [username, setUsername] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastname, setLastname] = useState('');
    const [dateBith, setDateBith] = useState('');
    const [phone, setPhone] = useState('');
    const [genre, setGenre] = useState('');
    const [email, setEmail] = useState('');
    const [citizenship, setCitizenship] = useState('');
    const [street, setStreet] = useState('');
    const [stateprovince, setStateprovince] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [govbranch, setGovbranch] = useState('');
    const [govstreet, setGovstreet] = useState('');
    const [govzipcode, setGovzipcode] = useState('');
    const [govcity, setGovcity] = useState('');
    const [country, setCountry] = useState('');
    const [govphone, setGovphone] = useState('');
    const [message, setMessage] = useState('');
    const [categori, setCategori] = useState('');

    useEffect(() => {
        if (!localStorage.getItem("token") || localStorage.getItem("token") == null) {
            navigate('/login')
        }
        countri();
        category();
        ListInvest();
    }, []);

    const ListInvest = () => {

        api.getHttpRequest('/customer/load/' + idcuctomer, data, 'GET').then((response) => {
            const liste = response.data.data;
            liste.forEach((element: any) => {
                if (element.id === idcuctomer) {
                    setCountry(element.id_country)
                    setUsername(element.name);
                    setMiddlename(element.middle_name);
                    setLastname(element.last_name);
                    setDateBith(element.date_of_birth);
                    setPhone(element.phone);
                    setGenre(element.gender);
                    setEmail(element.email);
                    setCitizenship(element.citizenship);
                    setStreet(element.adresse_street);
                    setStateprovince(element.state_province);
                    setCity(element.city);
                    setZipcode(element.codezip);
                    setGovbranch(element.gov_branch_name);
                    setGovstreet(element.gov_street);
                    setGovzipcode(element.gov_zip_code);
                    setGovphone(element.gov_phone);
                    setGovcity(element.gov_city);
                    setMessage('');
                    setCategori(element.id_category);
                }
            });
        }).catch((error) => {
            toast.error(error.message);
        })

    }

    // console.log(listinvset)
    const countri = () => {

        api.getHttpRequest('/configuration/country/load', data, 'GET').then((response) => {
            const result = response.data.data
            setListCountri(response.data.data);
        }).catch((error) => {
            toast.error(error.message);
        })

    }

    const category = () => {

        api.getHttpRequest('/configuration/category/load', data, 'GET').then((response) => {
            setListCategory(response.data.data);
        }).catch((error) => {
            toast.error(error.message);
        })

    }


    function updateCustomer(id: any) {
        var data = JSON.stringify({
            "id_country": country,
            "name": username,
            "middle_name": middlename,
            "last_name": lastname,
            "date_of_birth": dateBith,
            "phone": phone,
            "gender": genre,
            "email": email,
            "citizenship": citizenship,
            "adresse_street": street,
            "state_province": stateprovince,
            "city": city,
            "codezip": zipcode,
            "gov_branch_name": govbranch,
            "gov_street": govstreet,
            "gov_zip_code": govzipcode,
            "gov_choose_country": country,
            "gov_phone": govphone,
            "gov_city": govcity,
            "gov_note": message,
            "id_category": categori
        });

        api.getHttpRequest('/customer/update/' + id, data, 'PUT').then((response) => {
            if (response.data.status == 200) {
                const accessToken = response.data.accessToken;
                const roles = response.data.roles;
                setAuth({
                    username, country, middlename, lastname, dateBith, phone, genre, email,
                    citizenship, street, stateprovince, city, zipcode, govbranch, govstreet, govzipcode
                    , govphone, govcity, message, categori, accessToken, roles
                })
                setUsername('');
                setMiddlename('');
                setLastname('');
                setDateBith('');
                setPhone('');
                setGenre('');
                setEmail('');
                setCitizenship('');
                setStreet('');
                setStateprovince('');
                setCity('');
                setZipcode('');
                setGovbranch('');
                setGovstreet('');
                setGovzipcode('');
                setGovphone('');
                setGovcity('');
                setMessage('');
                setCategori('');
                toast.success(response.data.message);
            } else{
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error(error.message);
        })

    }

    const options = [
        {
            label: 'Male',
            value: 'Male'
        },
        {
            label: 'Female',
            value: 'Female'
        }
    ]

    return (
        <>
          <Header/>
            <div className="container-fluid">
                <div>
                    <Toaster />
                </div>

                <div className="col-xl-12">
                    <div className="d-sm-flex  d-block align-items-center mb-2 pt-2">
                        <div className="me-auto">
                            <h4 className="fs-20 text-black font-w600">Update member information</h4>
                        </div>
                        <div className="dropdown custom-dropdown mb-0 mt-3 mt-sm-0">
                            <div className="btn btn-light btn-rounded" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="las la-calendar-alt scale5 me-3"></i>
                                Filter Date
                                <i className="fa fa-caret-down text-primary ms-3" aria-hidden="true"></i>
                            </div>
                            <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href=";">Details</a>
                                <a className="dropdown-item text-danger" href=";">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Identity:</h4>
                            </div>
                            <div className="card-body">
                                <div id="smartwizard" className="form-wizard order-create">

                                    <div className="tab-content">
                                        <div role="tabpanel">
                                            <div className="row">
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Name<span className="required">*</span></label>
                                                        <input type="text" className="form-control" placeholder=""
                                                            id="nom" autoComplete="off" name="username" defaultValue={username}
                                                            onChange={(e) => { setUsername(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Middle Name<span className="required">*</span></label>
                                                        <input type="text" className="form-control"
                                                            id="middle" autoComplete="off" name="middlename" placeholder=""
                                                            defaultValue={middlename} onChange={(e) => { setMiddlename(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Last Name<span className="required">*</span></label>
                                                        <input type="text" className="form-control" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={lastname} onChange={(e) => { setLastname(e.target.value) }} name="lastname" id="last"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Date of Birth<span className="required">*</span></label>
                                                        <input type="date" name="dateBith" id="dtb" className=" form-control mb-3 mb-xl-0"
                                                            defaultValue={dateBith} onChange={(e) => { setDateBith(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Phone<span className="required">*</span></label>
                                                        <input type="text" name="phone" id="phn" className="form-control" placeholder="(+1)408-657-9007"
                                                            defaultValue={phone} onChange={(e) => { setPhone(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Gender<span className="required">*</span></label>
                                                        <select className="form-control default-select dashboard-select-2 wide w-100" id="gr" name="genre"
                                                            value={genre} onChange={(e) => { setGenre(e.target.value) }}
                                                        >
                                                            <option value="">Choose gender</option>
                                                            {options.map((option, i) => (
                                                                <option key={i} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Emai<span className="required">*</span></label>
                                                        <input type="email" className="form-control" name="email" id="eml"
                                                            value={email} onChange={(e) => { setEmail(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Citizenship<span className="required">*</span></label>
                                                        <input type="text" name="citizenship" id="ctzen" className="form-control"
                                                            value={citizenship} onChange={(e) => { setCitizenship(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Category<span className="required">*</span></label>
                                                        <select className="form-control default-select dashboard-select-2 wide w-100" name="categori" id="cat"
                                                            value={categori} onChange={(e) => { setCategori(e.target.value) }}
                                                        >
                                                            <option value="">Choose category</option>
                                                            {listCategory.map((category, i) => (
                                                                <option key={i} value={category.id}>{category.name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <h4 className="card-title">Your Address : </h4><br /><br />
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Street</label>
                                                        <input type="text" name="street" id="str" className="form-control" placeholder=""
                                                            value={street} onChange={(e) => { setStreet(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">City</label>
                                                        <input type="text" name="city" id="ct" className="form-control" placeholder=""
                                                            value={city} onChange={(e) => { setCity(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">State/Province</label>
                                                        <input type="text" className="form-control" name="stateprovince" id="sprv" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={stateprovince} onChange={(e) => { setStateprovince(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Zip Code</label>
                                                        <input type="text" className="form-control" name="zipcode" id="zcode" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={zipcode} onChange={(e) => { setZipcode(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>

                                                <h4 className="card-title">Government : </h4><br /><br />

                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Gov. Branch Name</label>
                                                        <input type="text" className="form-control" name="govbranch" id="Gov" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={govbranch} onChange={(e) => { setGovbranch(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Street</label>
                                                        <input type="text" className="form-control" name="govstreet" id="govStrt" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={govstreet} onChange={(e) => { setGovstreet(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Zip Code</label>
                                                        <input type="text" className="form-control" name="govzipcode" id="gvzp" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={govzipcode} onChange={(e) => { setGovzipcode(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">City</label>
                                                        <input type="text" className="form-control" name="govcity" id="gvct" aria-describedby="inputGroupPrepend2" placeholder="example@example.com.com"
                                                            defaultValue={govcity} onChange={(e) => { setGovcity(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Choose Country<span className="required">*</span></label>
                                                        <select className="form-control default-select dashboard-select-2 wide w-100" name="country" id="chooce"
                                                            value={country} onChange={(e) => { setCountry(e.target.value) }}
                                                        >
                                                            <option value="">Choose country</option>
                                                            {listCountri.map((country, i) => (
                                                                <option key={i} value={country.id}>{country.name}</option>
                                                            ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mb-2">
                                                    <div className="form-group">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" className="form-control" name="govphone" aria-describedby="inputGroupPrepend2" placeholder=""
                                                            defaultValue={govphone} onChange={(e) => { setGovphone(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-2">
                                                    <div className="form-group">
                                                        <textarea className="form-control" style={{ columns: 7 }} id="comment"></textarea>
                                                    </div>
                                                </div>

                                                <div className="toolbar toolbar-bottom" role="toolbar" style={{ textAlign: 'center' }}>
                                                    <button onClick={() => updateCustomer(idcuctomer)} className="btn btn-primary sw-btn-prev " type="button">Update</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCustomer
