import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';

import React from 'react'
import BtnAll from './BtnAll';
// import { theme } from '../../App';



const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    }
}))

const Popup = (props:any) => {

    const { title, children, openPupop, setOpenPopup } = props;
    const classes = useStyles();

    const hidePopup = () => {
        setOpenPopup(false);
    }

    return (
        <Dialog open={openPupop} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h6' component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <BtnAll appel='X' onClick={() => setOpenPopup(false)} width='30px' height='30px' color='white' border="none" backgroundColor='#197FC2' />
                </div>
            </DialogTitle>

            <DialogContent >
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default Popup