import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AunthLogin } from './authentification/context/AunthLogin';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <StrictMode>
      <AunthLogin>
          <App />
      </AunthLogin>
  // </StrictMode>

);
reportWebVitals();