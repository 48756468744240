import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import api from '../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import Row from '../../controller/PageRow';
import Popup from '../../components/Popup';
import QRCode from 'qrcode';
import Paginations from '../../controller/Paginations';
import {
	Menu
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import etat from './menuvoucher/Etat'
import Header from '../../components/nav/Header';
import swal from 'sweetalert';

const SerieVoucher = () => {
	const navigate = useNavigate()
	const data = '';
	const { idserie } = useParams();

	const [listVoucher, setlistVoucher] = useState<Row[]>([])
	const [searchResult, setSearchResult] = useState<Row[]>([])
	const [query, setQuery] = useState('');
	const [queryselec, setQueryselect] = useState('');

	const [recuqrcode, setRecuqrcode] = useState('');
	const [recuqrcode1, setRecuqrcode1] = useState('');
	const [openQr, setOpenQr] = useState(false);

	{/** variable de la pagination */ }
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

	const [menuVouche, setMenuVoucher] = useState<null | HTMLElement>(null)
	const openmenuVouche = Boolean(menuVouche)
	const showMenuVoucher = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuVoucher(event.currentTarget)
	}


	function handleCloseMenuVouche() {
		setMenuVoucher(null)
	}

	useEffect(() => {
		if (!localStorage.getItem("token") || localStorage.getItem("token") == null) {
            navigate('/login')
        }
		List();
	}, []);


	const List = () => {

		api.getHttpRequest("/vouchers/sous-serie/" + idserie, data, 'GET').then((response) => {
			const result = response.data.data
			setlistVoucher(result);
			setSearchResult(result);
			// setSearchResult(result)
		}).catch((error) => {
			toast.error(error.message);
		})

	}

	function exportVoucherSenodary(item: any) {
		let data = JSON.stringify({
			"type": "secondary",
			"serie": item
		});

		api.getHttpRequest('/vouchers/export', data, 'POST').then((response) => {
			if (response.data.status == 200) {
				toast.success(response.data.message);
				List();
			} else {
				toast.error(response.data.message);
			}
		}).catch((error) => {
			toast.error(error.message);
		})
	}

	const handleClickOpenQr = async (element: any) => {
		setOpenQr(true);
		setRecuqrcode1(idserie + element.serie_secondaire)
		try {
			const response = await QRCode.toDataURL(idserie + element.serie_secondaire)
			setRecuqrcode(response)

		} catch (error) {
			console.log(error);
		}
	};

	// function geDateFormat(date_:any) {

	// 	const date = new Date(date_);
	// 	const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
	// 	const formattedDate = date.toLocaleDateString('fr-FR', options);
	// 	return formattedDate;
	//   }

	function geDateFormat(dateString: string): string {
		const date = new Date(Date.parse(dateString));
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate;
	}

	//search to Input 
	const searchToInput = (event: any) => {
		const getSearch = event.target.value;
		if (getSearch.length > 0) {
			const searchdata = currentPosts.filter((item) => item.serie_secondaire.toLowerCase().includes(getSearch));
			setlistVoucher(searchdata)
		} else {
			setlistVoucher(searchResult)
		}
		setQuery(getSearch);
	}

	//function delete serie
	const handleDelete = (element: any) => {
		swal({
			title: `Are you sure, you want to delete this series ${idserie + element.serie_secondaire} ?`,
			text: "Once deleted, the series will not be visible on the interface!",
			icon: "warning",
			buttons: ["Cancel", "Delete"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					api.getHttpRequest(`/vouchers/delete/${element.serie_secondaire}`, data, 'delete').then((response) => {
						if (response.data.status == 200) {
							swal(response.data.message, {
								icon: "success",
							});
							List();
						} else {
							swal(response.data.message, {
								icon: "warning",
							});
						}
					}).catch((error) => {
						swal(error.message, {
							icon: "warning",
						});
					})

				} else {
					swal("The series will be kept!");
				}
			});

	}

	//Get current list page
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = listVoucher.slice(indexOfFirstPost, indexOfLastPost)
	const howManyPages = Math.ceil(listVoucher.length / postsPerPage);

	return (
		<>
		   <Header/>
			<div style={{ marginTop: 20 }} className="container-fluid">
				<div className="col-xl-12">
					<div className="d-sm-flex  d-block align-items-center mb-0 pt-2">
						<div className="me-auto">

							<div className="d-flex align-items-center mb-3 me-3">
								<i className="fas fa-sliders-h me-3" style={{ width: 68, height: 68, fill: "none", color: '#38B4EA' }}></i>
								{/* <svg className="me-3" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
								</svg> */}
								<div className="me-auto">
									<h5 className="fs-20 text-black font-w600">Display of subcategories of the series</h5>
									<span className="text-num text-black font-w600">{idserie}</span>
								</div>
							</div>
						</div>
						<div className="d-flex flex-wrap dropdown custom-dropdown mb-0 mt-3  mt-sm-0">
							<div className="btn btn-light " role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<div className='row'>
									<div className="col-xl-7 col-sm-6">
										<i className="flaticon-381-search-2"></i>
										<input type="text" className="form-control mb-xl-0 mb-0" id="search" value={query} onChange={(e) => searchToInput(e)} placeholder="Search Here...." />
									</div>
									{/* <div className="col-xl-6  col-sm-6 mb-0 mb-xl-0">
										<select className="form-control default-select dashboard-select-2 h-auto wide" aria-label="Default select example"
											value={queryselec} onChange={(e) => searchToSelect(e)}
										>
											<option value="">Choose jvpartner</option>
											{
												jvpartner.map((customer, i) => {
													if (customer.type === 'JV Parteners') {
														return <option key={i} value={customer.id_customer}>{customer.name + ' ' + customer.middle_name + ' ' + customer.last_name}</option>

													}

												})
											}

										</select>
									</div> */}
									<div className="col-xl-5 col-sm-6">
										<button className="btn btn-light btn-rounded" role="button"
											id='MenuVoucher'
											onClick={showMenuVoucher}
											aria-controls={openmenuVouche ? 'Voucher-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={openmenuVouche ? 'true' : undefined}
											style={{ backgroundColor: 'inherit', borderColor: 'inherit', border: 'none' }}
										>
											
											<i className="fas fa-bars scale5 me-3"></i>
											Navigate
											<i className="fa fa-caret-down text-primary ms-3" aria-hidden="true"></i>
										</button>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div>
						<Toaster />
					</div>
					<div className="col-lg-12">
						<div className="card">
							{/* <div className="card-header">
								<h4 className="card-title">Recent Payments Queue</h4>
							</div> */}
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-responsive-md">
										<thead>
											<tr>
												<th className="width80"><strong>#</strong></th>
												<th><strong>SERIES CATEGORIES</strong></th>
												<th><strong>AMOUNT</strong></th>
												<th><strong>NUMBER</strong></th>
												<th><strong>USED</strong></th>
												<th><strong>Created At</strong></th>
												<th><strong>EXPIREAT</strong></th>
												<th><strong>PRINT</strong></th>
												<th><strong>EXPORT</strong></th>
												<th><strong>ACTION</strong></th>
											</tr>
										</thead>
										<tbody>

											{currentPosts ? currentPosts.map((vouch, i) => {
												return (
													<tr key={i}>
														<td><strong>{i + 1}</strong></td>
														<td>{idserie + vouch.serie_secondaire}</td>
														<td className="width20"><span className=" badge badge-dark light">{ vouch.amount + ' ' + vouch.currency}</span></td>
														<td>{vouch.nombre}</td>
														<td><span className="badge badge-dark light">{vouch.used}</span></td>
														<td>{geDateFormat(vouch.createAt)}</td>
														<td><span className="badge badge-dark light">{geDateFormat(vouch.expireAt)}</span></td>
														<td><span className={etat.getPrint(vouch.is_print)}>{etat.getPrint1(vouch.is_print)}</span></td>
														<td><span className={etat.getExport(vouch.is_export)}>{etat.getExport1(vouch.is_export)}</span></td>
														<td>
															<div className="d-flex">
																<button style={{ marginTop: 5 }} onClick={() => navigate(`/serie_end/${idserie}/${vouch.serie_secondaire}`)} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fas fa-eye"></i></button>
																{/* <button style={{ marginTop: 5 }} onClick={() => { handleClickOpenQr(vouch) }} className="btn btn-dark shadow btn-xs sharp me-1"><i className="fas fa-qrcode"></i></button> */}
																<button style={{ marginTop: 5 }} onClick={() => { handleDelete(vouch) }} className="btn btn-danger shadow btn-xs sharp me-1"><i className="fas fa-trash-alt"></i></button>
																<div>
																	{(vouch.is_export) === '1' ? (
																		<Link to={vouch.url_zip_file} download={vouch.url_zip_file} className="btn btn-rounded btn-light me-1"  >
																			<i className="fas fa-download scale5 me-1" style={{ color: '#33C481' }}></i>Download</Link>
																	) : (
																		<button onClick={() => exportVoucherSenodary(idserie + vouch.serie_secondaire)} className="btn btn-rounded btn-light me-1"  >
																			<i className="fas fa-file-excel scale5 me-1" style={{ color: '#33C481' }}></i>Build</button>
																	)

																	}
																</div>
															</div>
														</td>
													</tr>
												)
											}) : null}
										</tbody>

									</table>
									<Paginations pages={howManyPages} setCurrentPage={setCurrentPage} />
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Popup
				title={'QrCode : ' + recuqrcode1}
				openPupop={openQr}
				setOpenPopup={setOpenQr}
			>

				<div className="col-xl-12 col-lg-12 col-sm-12">
					<div className="card overflow-hidden">
						<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
							<div className="profile-photo">
								<img src={recuqrcode} width="100" className="" alt="" />
							</div>
						</div>
						<div className="card-footer border-0 mt-0">
							<a href={recuqrcode} className="btn btn-primary btn-lg btn-block" download={recuqrcode1}>
								<i className="fas fa-download"></i> Downloard Qr code
							</a>
						</div>
					</div>
				</div>
			</Popup>


			<Menu
				id='MenuVoucher'
				anchorEl={menuVouche}
				open={openmenuVouche}
				onClose={handleCloseMenuVouche}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				MenuListProps={{
					'aria-labelledby': 'MenuVoucher'
				}}
			>
				<li className="nav-item dropdown header-profile">

					{/* <a href="app-profile.html" className="dropdown-item ai-icon">
                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span className="ms-2">Profile </span>
            </a>
            <a href="email-inbox.html" className="dropdown-item ai-icon">
                <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <span className="ms-2">Inbox </span>
            </a> */}
					<Link to="/vouchers" style={{ padding: 10 }} className="dropdown-item ai-icon">
						<i className="fas fa-poll-h fa-sm text-success" style={{ width: "30", height: "30", fill: "none", stroke: "currentColor", strokeWidth: "2" }}></i>

						<span className="ms-2">Series </span>
					</Link>

				</li>


			</Menu>
		</>
	)
}

export default SerieVoucher
