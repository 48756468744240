
const getPrint = (x: any) => {
    if (x <= 0) return 'badge light badge-success';
    if (x > 0) return 'badge light badge-danger';
    return '';
}

const getPrint1 = (x: any) => {
    if (x <= 0) return 'Not print';
    if (x > 0) return 'Is print';
    return '';
}

const getExport = (x: any) => {
    if (x <= 0) return 'badge light badge-warning';
    if (x > 0) return 'badge light badge-danger';
    return '';
}

const getExport1 = (x: any) => {
    if (x <= 0) return 'Not export';
    if (x > 0) return 'Is export';
    return '';
}



export default {getPrint, getPrint1, getExport, getExport1}