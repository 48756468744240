import { useState, useEffect } from 'react'
import SCLOGO from "../../assets/images/tiva-logo.png";
import {
 Menu
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { PROFILE } from '../icons';


//Integration fonctions du sous menus
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const Header = () => {
    const getuser = localStorage.getItem('user')
        const getType = localStorage.getItem('function')
    const navigate = useNavigate()
    const [dash] = useState(localStorage.getItem('title'))


    useEffect(() => {
    })

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [profil, setProfil] = useState<null | HTMLElement>(null)
    const openprofil = Boolean(profil)

    const showmenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const showProfil = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfil(event.currentTarget)
    }

    const handleClose = (title:any) => {
        setAnchorEl(null)
        localStorage.setItem('title',title)
    }
    const handleCloseMenu =() => {
        setAnchorEl(null)
    }
    const handleCloseProfile = () => {
        setProfil(null)
    }

    function logout_() {
        localStorage.clear();
    }
    return (
        <>
            <div className="nav-header">
                <Link to="/dashboard" className="brand-logo">

                    <img className="brand-title" src={SCLOGO} alt="" />
                </Link>

                <button style={{ border: 'none', backgroundColor: "inherit" }} className="nav-control"
                    id='Menus'
                    onClick={showmenu}
                    aria-controls={open ? 'services-menus' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <div className="hamburger"

                    >
                        <span className="line"></span><span className="line"></span><span className="line"></span>
                    </div>
                </button>
            </div >
            <div className="header">
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <div className="dashboard_bar">
                                    <div className="input-group search-area d-lg-inline-flex">
                                        {/* <div className="input-group-append">
                                            <button className="input-group-text search_icon"><i className="flaticon-381-search-2"></i></button>
                                        </div> */}
                                        <ul className="navbar-nav header-right">
                                            <li className="nav-item">
                                                <div className="d-flex weather-detail">
                                                    <span>Menu select</span>
                                                    {dash}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ul className="navbar-nav header-right">

                                <li className="nav-item  header-profile"
                                    
                                    style={{ backgroundColor: 'inherit', borderColor: 'inherit', border: 'none' }}
                                >
                                    <button className="header-info "
                                        style={{ backgroundColor: 'inherit', borderColor: 'inherit', border: 'none' }}
                                        id='Profil'
                                    onClick={showProfil}
                                    aria-controls={openprofil ? 'profil-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openprofil ? 'true' : undefined}
                                    >
                                        <span className="text-black"><strong style={{fontSize:9}}>{getuser}</strong></span>
                                        <p className="fs-14 mb-0">{getType}</p>
                                    </button>
                                    <button className="nav-link" role="button"
                                       id='Profil'
                                       onClick={showProfil}
                                       aria-controls={openprofil ? 'profil-menu' : undefined}
                                       aria-haspopup="true"
                                       aria-expanded={openprofil ? 'true' : undefined}
                                        style={{ backgroundColor: 'inherit', borderColor: 'inherit', border: 'none' }}
                                    >

                                        <img src={PROFILE()} width="20" alt="" />
                                    </button>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>


            <Menu
                id='notification'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                MenuListProps={{
                    'aria-labelledby': 'Menus'
                }}
            >

                <div className="deznav-scroll ">
                    <ul className="metismenu" id="menu" style={{ margin: 15 }}>

                        <li onClick={() => handleClose("Dashboard")} style={{ paddingBottom: 10, fontSize: 18 }}>
                            <Link to="/dashboard" className="dropdown-item ai-icon">

                                <i className="fa fa-home" style={{ backgroundColor: '#E3F9E9', color: '#2BC155', width: 40, height: 40, borderRadius: 5 }}></i>

                                <span style={{ marginTop: 30 }} className="ms-2 nav-text ">Dashboard </span>
                            </Link>
                        </li>

                        <li onClick={() => handleClose("Costumers")} style={{ paddingBottom: 10, fontSize: 18 }}>
                            <Link to="/customers" className="dropdown-item ai-icon">
                                <i className="far fa-user fa-lg  align-middle me-3" style={{ backgroundColor: '#F4F2FE', color: '#4922E7', width: 40, height: 40, borderRadius: 5 }}></i>
                                <span className="ms-2 nav-text">Costumers </span>
                            </Link>
                        </li>

                        <li onClick={() => handleClose("Vouchers")} style={{  fontSize: 18,  }}>
                            <Link to="/vouchers" className="dropdown-item ai-icon" >
                                <i className="fas fa-poll-h fa-sm  align-middle me-3" style={{ backgroundColor: '#D6F0FB', color: '#1EAAE7', width: 40, height: 40 , borderRadius: 5, 
                                 }}></i>
                                <span className="ms-2 nav-text">Vouchers </span>
                            </Link>
                        </li>

                    </ul>


                </div>
            </Menu>

            <Menu
                id='profil'
                anchorEl={profil}
                open={openprofil}
                onClose={handleCloseProfile}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                MenuListProps={{
                    'aria-labelledby': 'profil'
                }}
            >
                <li className="nav-item dropdown header-profile">

                    {/* <a href="app-profile.html" className="dropdown-item ai-icon">
                        <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span className="ms-2">Profile </span>
                    </a>
                    <a href="email-inbox.html" className="dropdown-item ai-icon">
                        <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        <span className="ms-2">Inbox </span>
                    </a> */}
                    <Link to="/login" onClick={logout_} style={{ padding: 10 }} className="dropdown-item ai-icon">
                        <i className="fas fa-sign-out text-danger" style={{ width: "18", height: "18", fill: "none", stroke: "currentColor", strokeWidth: "2" }}></i>
                        {/* <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg> */}
                        <span className="ms-2">Logout </span>
                    </Link>

                </li>


            </Menu>

        </>
    )
}

export default Header