import api from '../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import Row from '../../controller/PageRow';
import { useEffect, useState, useContext, useRef } from 'react';
import Popup from '../../components/Popup';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../authentification/context/AunthLogin';
import QRCode from 'qrcode';

import ReactDOMServer from 'react-dom/server';
import etat from './menuvoucher/Etat'
import { CARD_IMG } from '../../components/icons';
import Header from '../../components/nav/Header';
import swal from 'sweetalert';
import Paginations from '../../controller/Paginations';
import { Tabs, Tab } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import Barcode from 'react-barcode';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import fileSaver from 'file-saver';
import { BarcodeProps } from 'react-barcode';


const Voucher = () => {
	const data = '';

	const styleQr = {
		width: 100,
		height: 100,
	}

	const navigate = useNavigate()
	const { setAuth }: any = useContext(AuthContext);
	const [Loading, setLoading] = useState(false);
	const [page, setPage] = useState(1)
	const [listVoucher, setlistVoucher] = useState<Row[]>([]);
	const [searchResult, setSearchResult] = useState<Row[]>([])
	const [query, setQuery] = useState('');

	const [nombre, setNombre] = useState('');
	const [valeur, setValeur] = useState('');
	const [nombreByPacket, setNomberByPacket] = useState('');
	const [currency, setCurrency] = useState('');

	const [recuqrcode, setRecuqrcode] = useState('');
	const [recuqrcode1, setRecuqrcode1] = useState('');
	const [reqBarcode, setReqBarcode] = useState('');




	{/** variable de la pagination */ }
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

	//Affichage du popup
	const [open, setOpen] = useState(false);
	const [openQr, setOpenQr] = useState(false);
	function handleClickOpen() {
		setOpen(true);
	};
	const handleClickOpenQr = async (element: any) => {
		setOpenQr(true);
		setRecuqrcode1(element.series_voucher)
		setReqBarcode(element.series_voucher)
		try {
			const response = await QRCode.toDataURL(element.series_voucher)
			setRecuqrcode(response)

		} catch (error) {
			console.log(error);
		}
	};

	//downloadBarcode 
	const barcodeProps: BarcodeProps = {
		value: reqBarcode,

	};

	function downloadBarcode(val: any) {
		const canvas = document.getElementById(val) as HTMLCanvasElement;
		if (canvas == null) {
			console.error('Canvas is null');
			return;
		}

		const dataUrl = canvas.toDataURL() ?? '';
		const link = document.createElement('a');
		link.href = dataUrl;
		link.download = 'barcode.png';
		document.body.appendChild(link);
		link.click();
	}


	useEffect(() => {
		if (!localStorage.getItem("token") || localStorage.getItem("token") == null) {
			navigate('/login')
		}

		List();

	}, [page]);

	const List = () => {

		api.getHttpRequest("/vouchers/all-serie", data, 'GET').then((response) => {
			const result = response.data.data
			setlistVoucher(result);

			// setSearchResult(result)
		}).catch((error) => {
			toast.error(error.message);
		})

	}

	//function generate voucher
	const genarateVoucher = () => {
		let data = JSON.stringify({
			"nombre": nombre,
			"valeur": valeur,
			"nombre_by_packet": nombreByPacket,
			"currency": currency
		});

		if (nombre === '' || valeur === '' || nombreByPacket === '' || currency === '') {
			toast.error("The number, value, and number of packets fields must not be empty")
		} else {
			api.getHttpRequest('/vouchers/generate', data, 'POST').then((response) => {
				if (response.data.status == 200) {
					setNombre('');
					setValeur('');
					setNomberByPacket('');
					toast.success(response.data.message);
					navigate(`/serie_by_category/${response.data.data}`)
					setOpen(false);
				} else {
					toast.error(response.data.message);
				}
			}).catch((error) => {
				toast.error(error.message);
			})
		}
	}

	//function export voucher
	function exportVoucherPrincipal(item: any) {
		let data = JSON.stringify({
			"type": "principal",
			"serie": item
		});

		// setLoading(true);

		api.getHttpRequest('/vouchers/export', data, 'POST').then((response) => {
			if (response.data.status == 200) {
				toast.success(response.data.message);
				List();
				setLoading(true);
			} else {
				// setLoading(false);
				toast.error(response.data.message);
			}
		}).catch((error) => {
			// setLoading(false);
			toast.error(error.message);
		})

		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}

	//function delete serie
	const handleDelete = (element: any) => {
		swal({
			title: `Are you sure, you want to delete this series ${element.series_voucher} ?`,
			text: "Once deleted, the series will not be visible on the interface!",
			icon: "warning",
			buttons: ["Cancel", "Delete"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					api.getHttpRequest(`/vouchers/delete/${element.series_voucher}`, data, 'delete').then((response) => {
						if (response.data.status == 200) {
							swal(response.data.message, {
								icon: "success",
							});
							List();
						} else {
							swal(response.data.message, {
								icon: "warning",
							});
						}
					}).catch((error) => {
						swal(error.message, {
							icon: "warning",
						});
					})

				} else {
					swal("The series will be kept!");
				}
			});

	}


	{/**
    function
 */}
	const getShortVouch = (r: any) => {
		return '**********' + r.substring((r.length - 4), r.length)
	}

	const getUsed = (x: any) => {
		if (x == 1) return ['badge light badge-danger', 'Already use'];
		if (x == 0) return ['badge light badge-success', 'Not use'];
		return '';
	}

	//Formate Date
	function geDateFormat(dateString: string): string {
		const date = new Date(Date.parse(dateString));
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate;
	}

	//search to Input 
	const searchToInput = (event: any) => {
		const getSearch = event.target.value;
		if (getSearch.length > 0) {
			const searchdata = currentPosts.filter((item) => item.series_voucher.toLowerCase().includes(getSearch));
			setlistVoucher(searchdata)
		} else {
			setlistVoucher(searchResult)
		}
		setQuery(getSearch);
	}

	const options = [
		{
			label: 'USD',
			value: 'USD'
		},
		{
			label: `TZS-Tanzanian Shilling`,
			value: 'TZS'
		},
		{
			label: 'CDF-Congolese franc',
			value: 'CDF'
		}
	]

	// fucntion count serie voucher
	function countSeriesVoucher(array: any) {
		var count = 0;
		for (var i = 0; i < array.length; i++) {
			var obj = array[i];
			var series_voucher = obj.series_voucher;
			if (series_voucher) {
				count++;
			}
		}
		return count;
	}

	//Get current list page
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = listVoucher.slice(indexOfFirstPost, indexOfLastPost)
	const howManyPages = Math.ceil(listVoucher.length / postsPerPage);

	//Fuction qui me permet de faire le scrolle
	const [valueQrBar, setValueGrBar] = useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValueGrBar(newValue);
	};

	return (
		<>
			<Header />
			<div className="container-fluid">

				<div className="col-xl-12">
					<div className="d-sm-flex  d-block align-items-center mb-0 pt-2">
						<div className="me-auto">

							<div className="d-flex align-items-center mb-3 me-3">
								<i className="fas fa-poll-h  me-3" style={{ width: 68, height: 68, fill: "none", color: '#38B4EA' }}></i>
								{/* <svg className="me-3" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z" fill="#1EAAE7" />
								</svg> */}
								<div className="me-auto">
									<h5 className="fs-20 text-black font-w600">Series Voucher</h5>
									<div className="me-auto mb-sm-0 ">
										{/* <span className="fs-34 text-black font-w600">{countSeriesVoucher(listVoucher)}</span> */}
										<h3>Number : {countSeriesVoucher(listVoucher)}</h3>
										<div className="progress mb-2">
											<div className="progress-bar progress-animated bg-primary" style={{ width: '100%' }}></div>
										</div>
										<small>{countSeriesVoucher(listVoucher)} Is the total of the series</small>
									</div>
								</div>


							</div>

						</div>
						<div className="d-flex flex-wrap dropdown custom-dropdown mb-0 mt-3  mt-sm-0">
							<div className="btn btn-light " role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<div className='row'>
									<div className="col-xl-6 col-sm-6">
										<i className="flaticon-381-search-2"></i>
										<input type="text" className="form-control mb-xl-0 mb-0" id="search" value={query} onChange={(e) => searchToInput(e)} placeholder="Search Here...." />
									</div>
									<div className="col-xl-6  col-sm-6 mb-0 mb-xl-0">
										<button onClick={handleClickOpen} type="button" className="btn btn-rounded btn-primary" >
											<span className="btn-icon-left text-primary"><i className="fa fa-plus" aria-hidden="true"></i></span>
											Generate vouchers
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div>
						<Toaster />
					</div>
					<div className="col-lg-12">
						<div className="card">
							{/* <div className="card-header">
								<h4 className="card-title">Recent Payments Queue</h4>
							</div> */}
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-responsive-md">
										<thead>
											<tr>
												<th className="width20"><strong>#</strong></th>
												<th><strong>SERIES</strong></th>
												<th><strong>AMOUNT</strong></th>
												<th><strong>NUMBER</strong></th>
												<th><strong>USED</strong></th>

												<th><strong>CREATEAT</strong></th>
												<th><strong>EXPIREAT</strong></th>
												<th><strong>PRINT</strong></th>
												<th><strong>EXPORT</strong></th>
												<th><strong>Action</strong></th>
											</tr>
										</thead>
										<tbody>

											{currentPosts ? currentPosts.map((vouch, i) => {
												return (
													<tr key={i}>
														<td className="width20"><strong>{i + 1}</strong></td>
														<td>{vouch.series_voucher}</td>
														<td className="width20"><span className=" badge badge-dark light">{vouch.amount} {vouch.currency}</span> </td>
														<td>{vouch.nombre}</td>
														<td>{vouch.used}</td>

														<td>{geDateFormat(vouch.createAt)}</td>
														<td>{geDateFormat(vouch.expireAt)}</td>
														<td><span className={etat.getPrint(vouch.is_print)}>{etat.getPrint1(vouch.is_print)}</span></td>
														<td><span className={etat.getExport(vouch.is_export)}>{etat.getExport1(vouch.is_export)}</span></td>
														<td>
															<div className="d-flex">
																<button style={{ marginTop: 5 }} onClick={() => navigate(`/serie_by_category/${vouch.series_voucher}`)} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fas fa-eye"></i></button>
																{/* <button style={{ marginTop: 5 }} onClick={() => { handleClickOpenQr(vouch) }} className="btn btn-dark shadow btn-xs sharp me-1"><i className="fas fa-qrcode"></i></button> */}
																<button style={{ marginTop: 5 }} onClick={() => { handleDelete(vouch) }} className="btn btn-danger shadow btn-xs sharp me-1"><i className="fas fa-trash-alt"></i></button>
																<div>
																	{(vouch.is_export) === '1' ? (
																		<Link to={vouch.url_zip_file} download={vouch.url_zip_file} className="btn btn-rounded btn-light me-1"  >
																			<i className="fas fa-download scale5 me-1" style={{ color: '#33C481', fontSize: 14 }}></i>Download</Link>
																	) : (
																		<>
																			
																				<button onClick={() => exportVoucherPrincipal(vouch.series_voucher)} className="btn btn-rounded btn-light me-1"
																				//   disabled={Loading}
																				>
																					<i className="fas fa-file-excel scale5 me-1" style={{ color: '#33C481' }}></i>Build
																				</button>
																			
																			{/* {
																				Loading && (

																					<button key={i} className="btn btn-rounded btn-light me-1"
																						disabled
																					>
																						<i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i><span>processing...</span>

																					</button>

																				)
																			} */}

																		</>

																	)

																	}
																</div>

															</div>
														</td>
													</tr>
												)
											}) : null}
										</tbody>
									</table>

									<Paginations pages={howManyPages} setCurrentPage={setCurrentPage} />
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Popup
				title='Generate voucher'
				openPupop={open}
				setOpenPopup={setOpen}
			>

				<div className="basic-form">
					<div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number"
									name='nombre'
									value={nombre}
									onChange={(e) => { setNombre(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Value</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Value"
									name='valeur'
									value={valeur}
									onChange={(e) => { setValeur(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number by packet</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number by packet"
									name='nombreByPacket'
									value={nombreByPacket}
									onChange={(e) => { setNomberByPacket(e.target.value) }}
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Currency</label>
							<div className="col-sm-9">
								<select className="form-control" placeholder="number by packet"
									name='nombreByPacket'
									value={currency}
									onChange={(e) => { setCurrency(e.target.value) }}
								>
									<option value="" style={{ margin: 5 }}>Choose currency</option>
									{options.map((option, i) => (
										<option key={i} value={option.value}>{option.label}</option>
									))}
								</select>
							</div>
						</div>

						<div className="mb-3 ">
							<div className="col-sm-10">
								<button onClick={() => genarateVoucher()} type="submit" className="btn btn-primary">Generate</button>
							</div>
						</div>
					</div>
				</div>

			</Popup>

			<Popup
				title={'Download : ' + recuqrcode1}
				openPupop={openQr}
				setOpenPopup={setOpenQr}
			>

				<>
					<div className="profile-tab">
						<div className="custom-tab-1">
							<TabContext value={valueQrBar}>
								<Tabs
									value={valueQrBar}
									onChange={handleChange}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="scrollable auto tabs example"
									sx={{ bgcolor: '#FFFFFF' }}
								>
									<Tab
										label="QrCode"
										value='1'

									/>

									<Tab sx={{ pl: 6 }} value='2' label="BarCode" />
								</Tabs>
								<TabPanel value='1'>
									<div className="col-xl-12 col-lg-12 col-sm-12">
										<div className="card overflow-hidden">
											<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
												<div className="profile-photo">
													<img src={recuqrcode} width="150" className="" alt="" />
												</div>
											</div>

											<div className="card-footer border-0 mt-0">
												<a href={recuqrcode} className="btn btn-primary btn-lg btn-block" download={recuqrcode1}>
													<i className="fas fa-download"></i> Downloard Qr code
												</a>
											</div>
										</div>
									</div>
								</TabPanel>
								<TabPanel value='2'>
									<div className="col-xl-12 col-lg-12 col-sm-12">
										<div className="card overflow-hidden" id="my-node">
											{/* <input value={recuqrcode} onChange={(e) => {setReqBarcode(e.target.value)}}/> */}
											<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
												<div id="#barcode" className="profile-photo">

													<Barcode {...barcodeProps} />

												</div>
												<div>

													{/* <img src={`data:image/svg+xml;base64,${btoa(inputRef)}`} alt="barcode" /> */}
												</div>
											</div>

											<div className="card-footer border-0 mt-0">
												<button onClick={() => downloadBarcode(reqBarcode)} className="btn btn-primary btn-lg btn-block" >
													<i className="fas fa-download"></i> Downloard BarCode
												</button>
											</div>
										</div>
									</div>
								</TabPanel>
							</TabContext>


							{/* <div className="tab-content">
							<div id="my-posts" role="tabpanel" className="tab-pane fade active show">
								<div className="col-xl-12 col-lg-12 col-sm-12">
									<input />
									<div className="card overflow-hidden">
										<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
											<div className="profile-photo">
												<img src={recuqrcode} width="100" className="" alt="" />
											</div>
											<div>
												<img src={`data:image/svg+xml;base64,${btoa(reqBarcode)}`} alt="barcode" />
											</div>
										</div>

										<div className="card-footer border-0 mt-0">
											<a href={recuqrcode} className="btn btn-primary btn-lg btn-block" download={recuqrcode1}>
												<i className="fas fa-download"></i> Downloard Qr code
											</a>
										</div>
									</div>
								</div>
							</div>
							<div id="about-me" ref={BarcodeRef} role="tabpanel" className="tab-pane fade">
								<div className="profile-about-me">
									<div className="pt-4 border-bottom-1 pb-3">
										<h4 className="text-primary">About Me</h4>
										<p className="mb-2">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence was created for the bliss of souls like mine.I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.</p>
										<p>A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.</p>
									</div>
								</div>

							</div>

						</div> */}
						</div>
					</div>
				</>


			</Popup>

		</>
	)
}

export default Voucher
