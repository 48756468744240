import React, { useState, useEffect, useContext, useRef } from 'react'
import api from '../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { Link, Navigate } from "react-router-dom";
import AuthContext from './context/AunthLogin';
import { FadeLoader } from 'react-spinners';

const Login = () => {

    let dash = 'Dashboard'
    const { setAuth }: any = useContext(AuthContext);


    const [Loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    // const loginSubmit = async (e) => {
    //     e.preventDefault();

    //     var data = JSON.stringify({
    //         "username": username,
    //         "password": password
    //     });

    //     var config = {
    //         method: 'post',
    //         url: global.Config.api.url + '/auth/login',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             if (response.data.status === 200) {
    //                 const accessToken = response.data.token;
    //                 const roles = response.data.roles;
    //                 setAuth({ username, password, accessToken, roles })
    //                 setUsername('');
    //                 setPassword('');
    //                 setSuccess(true);
    //                 localStorage.setItem("token", response.data.token);
    //                 localStorage.setItem("id", response.data.data.id);
    //             } else {
    //                 // setErrMsg(response.data.message);
    //                 toast.error(response.data.message);
    //             }
    //         })
    //         .catch(function (error) {
    //             // setErrMsg(error.message);
    //             toast.error(error.message);
    //             errRef.current.focus();
    //         });
    // }

    const connectBtn = async () => {

        var data = JSON.stringify({
            "username": username,
            "password": password
        });

        setLoading(true);

        if (username === '' || password === '') {
            toast.error('The Email and Password fields must not be empty');

        } else {
            api.getHttpRequestNotToken("/auth/login", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    const accessToken = json.token;
                    const roles = response.data.roles;
                    setAuth({ username, password, accessToken, roles })
                    setUsername('');
                    setPassword('');
                    setSuccess(true);
                    localStorage.setItem("token", json.token);
                    localStorage.setItem("id", json.data.id);
                    localStorage.setItem("user", json.data.fullname + '' + json.data.middle_ame + ' ' + json.data.last_name);
                    localStorage.setItem("function", json.data.type);
                    localStorage.setItem('title', dash)
                } else {
                    // setLoading(false);
                    toast.error(json.message);
                }
            }).catch((error) => {
                // setLoading(false);
                toast.error(error.message);
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);


    }


    return (
        <>
            {success ? (
                <Navigate to='/dashboard' replace />
            ) : (
                <div className="h-100">
                    <div className="login-account">
                        <div className="row h-100">
                            <div>
                                <Toaster />
                            </div>
                            <div className="col-lg-6 align-self-start">
                                {/* style="background-image: url(images/rainbow.gif)" */}
                                <div className="account-info-area" >
                                    <div className="login-content">
                                        <p className="sub-title">The trusted Artificial intelligence Cloud
                                            Health Management Platform</p>
                                        <h1 className="title">Tiva | <span>Inc</span></h1>
                                        <p className="text">Tiva Platform offers unparalleled cloud-based health management solutions to healthcare facilities across the globe in order to streamline their business operations.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
                                <div className="login-form">
                                    <div className="login-head">
                                        <h3 className="title">LOGIN</h3>
                                        <p>Login to your account and start work.</p>
                                    </div>

                                    <div>
                                        <div className="mb-4">
                                            <label className="mb-1 text-black">Email</label>
                                            <input type="email" className="form-control"
                                                placeholder='Email Address'
                                                name="username"
                                                onChange={(e) => setUsername(e.target.value)}
                                                value={username}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="mb-1 text-black">Password</label>
                                            <input type="password" className="form-control" autoComplete="off" placeholder='Password'
                                                onChange={(e) => setPassword(e.target.value)}
                                                name='password'
                                                value={password}
                                            />
                                        </div>
                                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                            <div className="mb-4">
                                                <div className="form-check custom-checkbox mb-3">
                                                    <input type="checkbox" className="form-check-input" id="customCheckBox1" />
                                                    <label className="form-check-label" htmlFor="customCheckBox1">Remember my preference</label>
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <Link to="/login" className="btn-link text-primary">Forgot Password?</Link>
                                            </div>
                                        </div>
                                        <div className="text-center mb-4">
                                           
                                           {!Loading && (
                                              <button
                                              className="btn btn-primary btn-block"
                                              onClick={() => { connectBtn() }}
                                          >
                                               <span>Sign in</span>
                                          </button>
                                           )} 

                                           {Loading && (
                                               <button
                                               className="btn btn-primary btn-block"
                                               disabled
                                           >
                                               <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                              
                                           </button>
                                           )}
                                            
                                        </div>
                                        <p className="text-center">Not registered?
                                            <Link className="btn-link text-primary" to="/login">Register</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <script src="vendor/global/global.min.js"></script>
            <script src="vendor/bootstrap-select/dist/js/bootstrap-select.min.js"></script>
            <script src="js/custom.min.js"></script>
            <script src="js/deznav-init.js"></script> */}

                </div>
            )
            }
        </>

    )
}

export default Login
