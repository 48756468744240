import React, { Suspense } from 'react';
import './assets/css/style.css';
import { HashRouter, Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Login from './authentification/Login';
import DefaultLayout from './Layout/DefaultLayout';
import { Customer, Home, SerieByCategorie, SerieVoucher, Voucher, Wallet } from './components';
import UpdateCustomer from './models/customer/UpdateCustomer';

// interface PrivateRouteProps {
//    element: JSX.Element;
//    authenticated: boolean;
//  }
 
//  function PrivateRoute({ element, authenticated }: PrivateRouteProps) {
//    return authenticated ? element : <Navigate to="/login" />;
   
//  }
function App(){

   // const isAuthenticated =  localStorage.getItem("token") !== null;

      return (
         <>
            <HashRouter>
               <Suspense>
                  <Routes>
                     <Route path='/' element={<Login />} />
                     <Route path='/login' element={<Login />} />
                     
                     <Route path='/dashboard' element={<Home/>}/>
                     {/* <Route path="/dashboard" element={isAuthenticated ?  <PrivateRoute element={<Home />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} />
                     <Route path="/vouchers" element={isAuthenticated ?  <PrivateRoute element={<Voucher />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} /> */}
                     <Route path= '/vouchers' element={<Voucher/>}/>
                     {/* <Route path="/serie_by_category/:idserie" element={isAuthenticated ?  <PrivateRoute element={<SerieVoucher />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} /> */}
                     <Route path= '/serie_by_category/:idserie' element={<SerieVoucher/>}/>
                     {/* <Route path="/customers" element={isAuthenticated ?  <PrivateRoute element={<Customer />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} /> */}
                     <Route path= '/customers' element={<Customer/>}/> 
                     {/* <Route path="/update_customer/:idcuctomer" element={isAuthenticated ?  <PrivateRoute element={<UpdateCustomer />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} /> */}
                     <Route path='/update_customer/:idcuctomer' element={<UpdateCustomer/>}/>
                     {/* <Route path="/serie_end/:serie_principal/:serie_secondary" element={isAuthenticated ?  <PrivateRoute element={<SerieByCategorie />} authenticated={isAuthenticated} /> : <Navigate to="/login" />} /> */}
                     <Route path='/serie_end/:serie_principal/:serie_secondary' element={<SerieByCategorie/>}/>

                     {/* <Route path='*' element={<DefaultLayout />} /> */}
                  </Routes>
               </Suspense>
            </HashRouter>
         </>
      );

}

export default App;
