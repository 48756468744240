import React , {useEffect }from 'react'
import card from  '../assets/images/card/card.png'
import dot from '../assets/images/dot.png'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Header from '../components/nav/Header';


const Home = () => {
	const navigate = useNavigate()
	
	useEffect(() => { 
		if (!localStorage.getItem("token") || localStorage.getItem("token") == null) {
			navigate('/login');
        }
   },[])
	return (
		<>
           <Header/>

			<div className="container-fluid">
				<div className="form-head mb-4">
					<h2 className="text-black font-w1000 mb-0 pt-2">Dashboard</h2>
				</div>
				<div className="row">
					<div className="col-xl-6">
						<div className="row">
							<div className="col-xl-8 col-lg-6 col-md-7 col-sm-8">
								<div className="card-bx stacked">
									<img src={card} alt="" className="mw-100" />
									<div className="card-info text-white">
										<p className="mb-1">Main Balance</p>
										<h2 className="fs-36 text-white mb-sm-4 mb-3">$673,412.66</h2>
										<div className="d-flex align-items-center justify-content-between mb-sm-5 mb-3">
											<img src={dot} alt="" className="dot-img" />
											<h4 className="fs-20 text-white mb-0">**** **** **** 1234</h4>
										</div>
										<div className="d-flex">
											<div className="me-5">
												<p className="fs-14 mb-1 op6">VALID THRU</p>
												<span>08/21</span>
											</div>
											<div>
												<p className="fs-14 mb-1 op6">CARD HOLDER</p>
												<span>Franklin Jr.</span>
											</div>
										</div>
									</div>
									<a href="cards-center.html"><i className="fa fa-caret-down" aria-hidden="true"></i></a>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-5 col-sm-4">
								<div className="card bgl-primary card-body overflow-hidden p-0 d-flex rounded">
									<div className="p-0 text-center mt-3">
										<span className="text-black">Limit</span>
										<h3 className="text-black fs-20 mb-0 font-w600">$4,000</h3>
										<small>/$10,000</small>
									</div>
									<canvas id="lineChart" height="300" className="mt-auto line-chart-demo"></canvas>
								</div>
							</div>
							
							
						</div>
					</div>
					<div className="col-xl-6">
						<div className="row">
							<div className="col-xl-6 col-sm-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0">
										<div className="me-3 mb-2">
											<p className="fs-14 mb-1">Income</p>
											<span className="fs-24 text-black font-w600">$65,123</span>
										</div>
										<span className="fs-12 mb-2">
											<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5" stroke="#2BC155" strokeWidth="2" />
												<path d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z" fill="url(#paint0_linear)" />
												<defs>
													<linearGradient id="paint0_linear" x1="10.2499" y1="3" x2="10.9999" y2="13.5" gradientUnits="userSpaceOnUse">
														<stop offset="0" stopColor="#2BC155" stopOpacity="0.73" />
														<stop offset="1" stopColor="#2BC155" stopOpacity="0" />
													</linearGradient>
												</defs>
											</svg>
											4% (30 days)</span>
									</div>
									<div className="card-body p-0">
										<svg width="400" height="100" viewBox="0 0 21 15" fill="none" >
												<path d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5" stroke="#2BC155" strokeWidth="2" />
												<path d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z" fill="url(#paint0_linear)" />
												<defs>
													<linearGradient id="paint0_linear" x1="10.2499" y1="3" x2="10.9999" y2="13.5" gradientUnits="userSpaceOnUse">
														<stop offset="0" stopColor="#2BC155" stopOpacity="0.73" />
														<stop offset="1" stopColor="#2BC155" stopOpacity="0" />
													</linearGradient>
												</defs>
											</svg>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-sm-6">
								<div className="card">
									<div className="card-header flex-wrap border-0 pb-0">
										<div className="me-3 mb-2">
											<p className="fs-14 mb-1">Outome</p>
											<span className="fs-24 text-black font-w600">$24,551</span>
										</div>
										<span className="fs-12 mb-2">
											<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z" fill="url(#paint0_linear1)" />
												<path d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5" stroke="#FF2E2E" strokeWidth="2" strokeLinecap="round" />
												<defs>
													<linearGradient id="paint0_linear1" x1="10.5" y1="2.625" x2="9.64345" y2="13.9935" gradientUnits="userSpaceOnUse">
														<stop offset="0" stopColor="#FF2E2E" />
														<stop offset="1" stopColor="#FF2E2E" stopOpacity="0.03" />
													</linearGradient>
												</defs>
											</svg>
											4% (30 days)</span>
									</div>
									<div className="card-body p-0">
									<svg width="400" height="100" viewBox="0 0 21 15" fill="none" >
												<path d="M14.3514 7.5C15.9974 9.37169 19.0572 12.8253 20 14H1V1L8.18919 10.75L14.3514 7.5Z" fill="url(#paint0_linear1)" />
												<path d="M19.5 13.5C18.582 12.4157 15.6027 9.22772 14 7.5L8 10.5L1 1.5" stroke="#FF2E2E" strokeWidth="2" strokeLinecap="round" />
												<defs>
													<linearGradient id="paint0_linear1" x1="10.5" y1="2.625" x2="9.64345" y2="13.9935" gradientUnits="userSpaceOnUse">
														<stop offset="0" stopColor="#FF2E2E" />
														<stop offset="1" stopColor="#FF2E2E" stopOpacity="0.03" />
													</linearGradient>
												</defs>
											</svg>
									</div>
								</div>
							</div>
							<div className="col-xl-12">
								<div className="card overflow-hidden">
									<div className="card-header d-sm-flex d-block border-0 pb-0">
										<div className="mb-sm-0 mb-2">
											<p className="fs-14 mb-1">Weekly Wallet Usage</p>
											<span className="mb-0">
												<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11.9999 6L5.99994 -2.62268e-07L-6.10352e-05 6" fill="#2BC155" />
												</svg>
												<strong className="fs-24 text-black ms-2 me-3">43%</strong>Than last week</span>
										</div>
										<span className="fs-12">
											<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M0.999939 13.5C1.91791 12.4157 4.89722 9.22772 6.49994 7.5L12.4999 10.5L19.4999 1.5" stroke="#2BC155" strokeWidth="2" />
												<path d="M6.49994 7.5C4.89722 9.22772 1.91791 12.4157 0.999939 13.5H19.4999V1.5L12.4999 10.5L6.49994 7.5Z" fill="url(#paint0_linear2)" />
												<defs>
													<linearGradient id="paint0_linear2" x1="10.2499" y1="3" x2="10.9999" y2="13.5" gradientUnits="userSpaceOnUse">
														<stop offset="0" stopColor="#2BC155" stopOpacity="0.73" />
														<stop offset="1" stopColor="#2BC155" stopOpacity="0" />
													</linearGradient>
												</defs>
											</svg>
											4% (30 days)</span>
									</div>
									<div className="card-body p-0">
										<canvas id="widgetChart3" height="80"></canvas>
									</div>
								</div>
							</div>
							
						</div>
					</div>

					<div className="col-xl-12">
								<div className="card">
									<div className="card-header d-sm-flex d-block border-0 pb-0 flex-wrap">
										<div className="pr-3 me-auto mb-sm-0 mb-3">
											<h4 className="fs-20 text-black mb-1">Transaction Overview</h4>
											<span className="fs-12">Tiva Platform offers unparalleled cloud-based health 
											management solutions to healthcare facilities across 
											the globe in order to streamline their business operations.</span>
										</div>
										<div className="d-flex align-items-center justify-content-between">
											<button  className="btn btn-rounded btn-light me-3" data-bs-toggle="modal" data-bs-target="#DownloadReport"><i className="las la-download text-primary scale5 me-3"></i>Download Report</button>

											<div className="modal fade" id="DownloadReport">
												<div className="modal-dialog modal-dialog-centered" role="document">
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title">Modal title</h5>
															<button type="button" className="close" data-bs-dismiss="modal"><span>&times;</span>
															</button>
														</div>
														<div className="modal-body">
															<p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
															<button type="button" className="btn btn-primary">Save changes</button>
														</div>
													</div>
												</div>
											</div>
											<div className="dropdown c-pointer">
												<div className="btn-link" data-bs-toggle="dropdown">
													<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
												</div>
												<div className="dropdown-menu dropdown-menu-end">
													<a className="dropdown-item" href="">Delete</a>
													<a className="dropdown-item" href="">Edit</a>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div id="chartBar"></div>
										<div className="d-flex">
											<div className="form-check custom-switch toggle-switch text-end me-4">
												<input type="checkbox" className="form-check-input mt-0" id="customSwitch11" />
												<label className="form-check-label fs-14 text-black pe-2" htmlFor="customSwitch11">Number</label>
											</div>
											<div className="form-check custom-switch toggle-switch text-end me-4">
												<input type="checkbox" className="form-check-input mt-0" id="customSwitch12" />
												<label className="form-check-label fs-14 text-black pe-2" htmlFor="customSwitch12">Analytics</label>
											</div>
										</div>
									</div>
								</div>
							</div>
				</div>
			</div>

         
		</>
	)
}

export default Home
