import user from '../assets/images/user.png';
import tanzani from '../assets/images/tanzani.svg';
import card1 from '../assets/images/card1.png'

export const PROFILE = () => {
    return (
        user
    )
}

export const IMG_TANZANI = () => {
    return (
        tanzani
    )
}

export const CARD_IMG = () => {
    return (
        card1
    )
}