import React, { useEffect, useState } from 'react'



function Paginations({ pages = 0, setCurrentPage }) {

    //Set number of pagees
    const numbeOfPages = [];

    for (let i = 1; i < pages; i++) {
        numbeOfPages.push(i);
    }

    const [currentButton, setCurrentButton] = useState(1)

    const [arrOfCurrButtons, setArrCurrButtons] = useState([])

    useEffect(() => {
        let tempNumberOfPages = [...arrOfCurrButtons];

        let dotsInitial = '...';
        let dotsLeft = '...';
        let dotsRight = '...';

        if (numbeOfPages.length < 1) {
            tempNumberOfPages = numbeOfPages;
        }

        else if (currentButton >= 1 && currentButton <= 3) {
            tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numbeOfPages.length]
        }

        else if (currentButton === 4) {
            const sliced = numbeOfPages.slice(0, 5)
            tempNumberOfPages = [...sliced, dotsInitial, numbeOfPages.length]
        }

        else if (currentButton === 4 && currentButton < numbeOfPages.length - 2) {
            const sliced1 = numbeOfPages.length.slice(currentButton - 2, currentButton)
            const sliced2 = numbeOfPages.length.slice(currentButton, currentButton + 1)
            tempNumberOfPages = ([1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numbeOfPages.length])
        }

        else if (currentButton > numbeOfPages.length - 3) {
            const sliced = numbeOfPages.slice(numbeOfPages.length - 4)
            tempNumberOfPages = ([1, dotsLeft, ...sliced])
        }

        else if (currentButton === dotsInitial) {
            setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1)
        }

        else if (currentButton === dotsRight) {
            setCurrentButton(arrOfCurrButtons[3] - 2)
        }

        else if (currentButton === dotsLeft) {
            setCurrentButton(arrOfCurrButtons[3] - 2)
        }

        setArrCurrButtons(tempNumberOfPages);
        setCurrentPage(currentButton)
    }, [currentButton])

    return (

        <div className="d-flex align-items-center justify-content  flex-wrap justify-content-center mt-3">

            <nav aria-label="Page navigation example">
                <div className="pagination mb-2 mb-sm-0">
                    <div className="page-item">
                        <button className={` ${currentButton === 1 ? 'page-link' : 'page-link'}`}
                            onClick={() => setCurrentButton(prev => prev <= 1 ? prev : prev - 1)}
                        ><i className="fa-solid fa-angle-left"></i></button></div>

                    {
                        arrOfCurrButtons.map(((item, i) => {
                            return <span key={i}>
                                <button className={` ${currentButton === item ? 'page-link' : 'page-link'}`}
                                    onClick={() => setCurrentButton(item)}
                                    key={i}>{item}</button>
                            </span>

                        }))
                    }


                    <div className="page-item">
                        <button className={` ${currentButton === numbeOfPages.length ? 'page-link' : 'page-link'}`}
                            onClick={() => setCurrentButton(prev => prev >= numbeOfPages.length ? prev : prev + 1)}
                        ><i className="fa-solid fa-angle-right"></i></button></div>
                </div>
            </nav>
        </div>


    )
}

export default Paginations
